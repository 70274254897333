// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@picooc/assets/icons/gift.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".gift-label{display:flex;align-items:center;position:absolute;top:-4px;left:-4px;z-index:10;background-color:var(--2vd35v);border-radius:50px;color:#fff;font-size:0;line-height:0;width:28px;height:28px;overflow:hidden;transition:width .2s ease-in}@media only screen and (min-width:360px){.gift-label{width:auto;height:auto;font-weight:600;font-size:9px;line-height:12px;padding:8px}}@media only screen and (min-width:375px){.gift-label{font-size:10px;padding:8px 9px}}@media only screen and (min-width:992px){.gift-label{top:-8px;left:-8px;padding:0;font-size:14px;width:40px;height:40px}.gift-label:hover{width:100px}}.gift-label:before{content:\"\";flex-shrink:0;width:12px;height:12px;margin:0 auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}@media only screen and (min-width:360px){.gift-label:before{margin-right:2px}}@media only screen and (min-width:992px){.gift-label:before{margin-left:10px;margin-right:11px;width:20px;height:20px}}.gift-label__text{white-space:nowrap}.gift-label--detail{top:8px;left:16px}@media only screen and (min-width:992px){.gift-label--detail{top:0;left:0}.gift-label--detail:hover{width:180px}}.gift-label--lg{top:-12px;left:-12px}@media only screen and (min-width:992px){.gift-label--lg{width:50px;height:50px}.gift-label--lg:hover{width:110px}}@media only screen and (min-width:992px){.gift-label--lg:before{margin-left:12px;margin-right:13px;width:25px;height:25px}}", ""]);
// Exports
module.exports = exports;
