<script>
    import { removeDuplicates } from "@/utils";

    export default {
        name: "ColorList",
        props: {
            typeDirection: {
                default: "row",
                type: String,
            },
            currentOffer: Object,
            offers: Array,
            isDecorative: Boolean,
        },
        data() {
            return {
                currentColor: this.currentOffer.color?.id,
                // проверка сброса цвета при перходах между товарами
            };
        },
        computed: {
            listColors() {
                return removeDuplicates(
                    this.offers
                        .filter((offer) => offer.color && offer.available)
                        .map((offer) => offer.color)
                );
            },
        },
        methods: {
            selectColor(color) {
                this.currentColor = color.id;
                this.$emit("select-color", color);
            },
            createBgStyle(codeColor) {
                return `background-color: #${codeColor};`;
                // return codeColor === "ffffff"
                //     ? `background-color: #${codeColor}; border: 1px solid #bdbdbd;`
                //     : `background-color: #${codeColor};`;
            },
        },
    };
</script>

<template>
    <div
        class="c-color-list"
        :class="{
            'c-color-list--row': typeDirection === 'row',
            'c-color-list--column': typeDirection === 'column',
            'c-color-list--decorative': isDecorative,
            'c-color-list--single': listColors.length < 2,
        }"
    >
        <div
            class="c-color-list__item"
            :class="{
                'c-color-list__item--active': currentColor === color.id && !isDecorative,
            }"
            :style="createBgStyle(color.code)"
            role="img"
            :alt="color.name + ' цвет'"
            v-for="color in listColors"
            :key="color.id"
            @click="selectColor(color)"
        >
            <div class="c-color-list__item-circle"></div>
        </div>
    </div>
</template>

<style lang="scss">
    .c-color-list {
        display: flex;
    }

    .c-color-list__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        @include box(20px);
        border-radius: 50%;
        transition: border 0.2s ease-in;
        cursor: pointer;
        border: 2px solid #b8e7ff;

        &:hover {
            .c-color-list__item-circle {
                transform: scale(1.05);
            }
        }
    }

    .c-color-list__item-circle {
        position: absolute;
        top: 0;
        left: 0;
        @include box(20px);
        border-radius: 50%;
    }

    //
    // --mod
    //

    .c-color-list--decorative {
        pointer-events: none;
    }

    .c-color-list__item--active {
        pointer-events: none;
        border-color: #00a6ed;

        &:hover {
            .c-color-list__item-circle {
                transform: scale(1);
            }
        }
    }

    .c-color-list--single {
        visibility: hidden;
    }
</style>
