<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { sendAnalytics } from "@/core/SendAnalytics";

    export default {
        name: "ButtonCompare",
        props: {
            size: String,
            productId: Number,
            isCloneProduct: Boolean,
            isTextOnMobile: Boolean,
        },
        data() {
            return {
                currentInComparison: false,
                isLoading: false,
            };
        },
        computed: {
            ...mapState("Compare", ["comparisonIdsList"]),
            textForCompare() {
                return this.size === "lg"
                    ? this.currentInComparison
                        ? "Перейти к сравнению"
                        : "Добавить к сравнению"
                    : this.currentInComparison
                    ? "Сравниваем"
                    : "К сравнению";
            },
        },
        watch: {
            comparisonIdsList: {
                handler(updatedIdsList) {
                    this.checkComparisonState(updatedIdsList);
                },
            },
        },
        mounted() {
            this.checkComparisonState(this.comparisonIdsList);
        },
        methods: {
            ...mapActions("Compare", ["addToComparison"]),
            ...mapMutations("Compare", {
                setComparison: "SET_COMPARISON_FOR_CLONE",
            }),
            checkComparisonState(comparisonIdsList) {
                let idsList = [];
                comparisonIdsList.forEach((obj) => {
                    idsList.push(obj.id);
                });
                this.currentInComparison = idsList.includes(this.productId);
            },
            addToComparisonHandler() {
                this.addToComparison(this.productId).then(() => {
                    this.isLoading = false;
                    this.currentInComparison = true;
                    sendAnalytics.send(sendAnalytics.events.compare);
                    if (this.isCloneProduct) {
                        this.setComparison({ type: "add", id: this.productId });
                    }
                });
            },
            goToCompare() {
                this.isLoading = false;
                this.$router.push({ name: "Compare" });
            },
            toggleComparisonHandler() {
                this.isLoading = true;
                if (this.currentInComparison) {
                    this.goToCompare();
                } else {
                    this.addToComparisonHandler();
                }
            },
        },
    };
</script>

<template>
    <button
        class="button-compare outline"
        :class="{
            'is-loading': isLoading,
            'is-comparison': currentInComparison,
            'button-compare--lg': size === 'lg',
            'button-compare--md': size === 'md',
            'button-compare--mobile-text': isTextOnMobile,
        }"
        @click="toggleComparisonHandler"
    >
        <span class="button-compare__wrapper">
            <span class="button-compare__line button-compare__line--1"></span>
            <span class="button-compare__line button-compare__line--2"></span>
            <span class="button-compare__line button-compare__line--3"></span>
        </span>

        <span class="button-compare__text">
            {{ textForCompare }}
        </span>
    </button>
</template>

<style lang="scss">
    .button-compare {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 0 12px 0 12px;
        padding: 4px 7px 5px 7px;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            padding: 6px 12px 8px 12px;
            border-radius: 0 20px 0 20px;
        }

        &:hover {
            .button-compare__text {
                color: var(--primary--lightest);
            }

            .button-compare__line {
                background-color: var(--primary--lightest);
            }
        }

        &.is-comparison {
            .button-compare__text {
                color: var(--success);
            }
        }
    }

    .button-compare__wrapper {
        display: none;
        @include box(21px);

        @include bp($bp-desktop-sm) {
            margin-right: 8px;
            @include box(18px);
        }
    }

    .button-compare__text {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--primary--dark);
        transition: 0.3s ease-in-out color;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .button-compare__line {
        width: 2px;
        margin: auto 2px 0;
        background-color: var(--primary--dark);
        transition: 0.3s ease-in-out all;
        border-radius: 1px;
    }

    .button-compare__line--1 {
        height: 13px;

        @include bp($bp-desktop-sm) {
            height: 11px;
        }
    }

    .button-compare__line--2 {
        height: 7px;

        @include bp($bp-desktop-sm) {
            height: 6px;
        }
    }

    .button-compare__line--3 {
        height: 21px;

        @include bp($bp-desktop-sm) {
            height: 18px;
        }
    }

    //
    // --mod
    //

    .button-compare--lg {
        font-size: 14px;
        line-height: 26px;
        @include box(50px);
        border: 2px solid var(--primary--dark);
        border-radius: 50%;

        .button-compare__wrapper {
            display: flex;
        }

        @include bp($bp-desktop-sm) {
            @include box(auto);
            border: none;

            &:not(.is-comparison):hover {
                .button-compare__line--1 {
                    height: 18px;
                }

                .button-compare__line--2 {
                    height: 5px;
                }

                .button-compare__line--3 {
                    height: 11px;
                }
            }
        }

        &.is-comparison {
            background-color: #fff;
            border-color: var(--success);

            @include bp($bp-desktop-sm) {
                background-color: transparent;
            }

            .button-compare__line {
                background-color: var(--success);
            }
        }

        .button-compare__text {
            display: none;

            @include bp($bp-desktop-sm) {
                display: block;
            }
        }
    }

    .button-compare--md {
        border: 2px solid var(--primary--dark);
        padding: 0;
        @include box(40px);
        border-radius: 50%;
        transition: 0.3s ease-in-out border;

        &:hover {
            border-color: var(--primary--lightest);
        }

        &.is-comparison {
            border-color: var(--success);

            .button-compare__line {
                background-color: var(--success);
            }
        }

        .button-compare__text {
            font-size: 0;
        }

        .button-compare__wrapper {
            margin-right: 0;
            display: flex;
        }
    }

    .button-compare--mobile-text {
        align-items: flex-end;
        @include box(auto);
        padding: 0;
        border: none;
        border-radius: 0;
        .button-compare__text {
            display: block;
        }
    }
</style>
