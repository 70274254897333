<script>
    export default {
        name: "GiftLabel",
        props: {
            isDetail: Boolean,
            isLg: Boolean,
        },
        computed: {
            text() {
                return this.isDetail ? "ленты в подарок" : "ленты";
            },
        },
    };
</script>

<template>
    <div
        class="gift-label"
        :class="{ 'gift-label--detail': isDetail, 'gift-label--lg': isLg }"
    >
        <span class="gift-label__text">{{ text }}</span>
    </div>
</template>

<style lang="scss">
    .gift-label {
        display: flex;
        align-items: center;
        position: absolute;
        top: -4px;
        left: -4px;
        z-index: 10;
        background-color: var(--label);
        border-radius: 50px;
        color: #ffffff;
        font-size: 0;
        line-height: 0;
        @include box(28px);
        overflow: hidden;
        transition: width 0.2s ease-in;

        @include bp($bp-mobile-xs) {
            @include box(auto);
            font-weight: 600;
            font-size: 9px;
            line-height: 12px;
            padding: 8px;
        }

        @include bp($bp-mobile-s) {
            font-size: 10px;
            padding: 8px 9px;
        }

        @include bp($bp-desktop-sm) {
            top: -8px;
            left: -8px;
            padding: 0;
            font-size: 14px;
            @include box(40px);
            &:hover {
                width: 100px;
            }
        }

        &:before {
            content: "";
            flex-shrink: 0;
            @include box(12px);
            margin: 0 auto;
            background-image: url("~@picooc/assets/icons/gift.svg");
            background-size: contain;
            @include bp($bp-mobile-xs) {
                margin-right: 2px;
            }

            @include bp($bp-desktop-sm) {
                margin-left: 10px;
                margin-right: 11px;
                @include box(20px);
            }
        }
    }

    .gift-label__text {
        white-space: nowrap;
    }

    .gift-label--detail {
        top: 8px;
        left: 16px;

        @include bp($bp-desktop-sm) {
            top: 0;
            left: 0;
            &:hover {
                width: 180px;
            }
        }
    }

    .gift-label--lg {
        top: -12px;
        left: -12px;

        @include bp($bp-desktop-sm) {
            @include box(50px);
            &:hover {
                width: 110px;
            }
        }

        &:before {
            @include bp($bp-desktop-sm) {
                margin-left: 12px;
                margin-right: 13px;
                @include box(25px);
            }
        }
    }
</style>
