var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-color-list",class:{
        'c-color-list--row': _vm.typeDirection === 'row',
        'c-color-list--column': _vm.typeDirection === 'column',
        'c-color-list--decorative': _vm.isDecorative,
        'c-color-list--single': _vm.listColors.length < 2,
    }},_vm._l((_vm.listColors),function(color){return _c('div',{key:color.id,staticClass:"c-color-list__item",class:{
            'c-color-list__item--active': _vm.currentColor === color.id && !_vm.isDecorative,
        },style:(_vm.createBgStyle(color.code)),attrs:{"role":"img","alt":color.name + ' цвет'},on:{"click":function($event){return _vm.selectColor(color)}}},[_c('div',{staticClass:"c-color-list__item-circle"})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }