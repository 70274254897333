var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card",class:{
        'product-card--loading': _vm.loading,
        'product-card--sm': _vm.isSmall,
        'product-card--tonometer': _vm.product.id === 216925,
        'product-card--strip': _vm.product.id === 214411,
    }},[(_vm.product.canCompared)?_c('button-compare',{staticClass:"product-card__compare outline",attrs:{"product-id":_vm.product.id,"is-clone-product":_vm.product.isClone}}):_vm._e(),(_vm.product.showGiftIcon)?_c('gift-label'):_vm._e(),_c('div',{staticClass:"product-card__header"},[_c('div',{staticClass:"product-card__img"},[_c('router-link',{staticClass:"product-card__img-link js-lazy-load",attrs:{"to":{
                    name: 'Product',
                    params: { slug: _vm.product.slug, offer: _vm.offerForParams },
                },"tabindex":"-1"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('img',{staticClass:"product-card__img-product",attrs:{"data-src":_vm.apiUrl(_vm.previewImage)}}),_c('div',{staticClass:"product-card__preloader"},[_c('preloader',{attrs:{"show":true,"lg-size":""}})],1),_c('img',{staticClass:"product-card__img-shadow",attrs:{"data-src":_vm.srcShadow}}),(_vm.product.showGiftIcon)?_c('img',{staticClass:"product-card__img-gift",attrs:{"data-src":"/static/picooc/scales/gift.png","alt":""}}):_vm._e()])],1),(_vm.offer.color)?_c('div',{staticClass:"product-card__colors"},[_c('color-list',{attrs:{"offers":_vm.currentOffers,"current-offer":_vm.offer},on:{"select-color":_vm.selectColor}})],1):_vm._e()]),_c('router-link',{staticClass:"product-card__wrap",attrs:{"to":{
            name: 'Product',
            params: { slug: _vm.product.slug, offer: _vm.offerForParams },
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendDataLayerEvent.apply(null, arguments)}}},[_c('div',{staticClass:"product-card__name"},[_vm._v(" "+_vm._s(_vm.nameProduct)+" ")]),_c('div',{staticClass:"product-card__price"},[_c('product-price',{attrs:{"price":_vm.discountPrice,"old-price":_vm.price,"discount-count":_vm.discountPercent,"is-card-size":""}})],1)]),_c('product-card-buttons',{attrs:{"offer-id":_vm.offerId,"product":_vm.product,"is-pre-order":_vm.isPreOrder,"in-cart":_vm.inCart,"discount-price":_vm.discountPrice,"size-button":_vm.sizeButton},on:{"on-added-to-cart":function($event){_vm.inCart = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }