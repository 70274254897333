<script>
    import { apiUrl } from "@/utils";
    import { sendAnalytics } from "@/core/SendAnalytics";
    import ProductCardButtons from "@picooc/components/ProductCardButtons";
    import ColorList from "@picooc/components/elements/ColorList";
    import ProductPrice from "@picooc/components/ProductPrice";
    import ButtonCompare from "@picooc/components/ui/ButtonCompare";
    import GiftLabel from "@picooc/components/GiftLabel";

    export default {
        name: "ProductCard",
        components: {
            GiftLabel,
            ButtonCompare,
            ProductPrice,
            ProductCardButtons,
            ColorList,
        },
        props: {
            product: Object,
            position: Number,
            isSearch: Boolean,
            isAvalible: Boolean,
            isSmall: Boolean,
        },
        data() {
            let isPreOrder = false;
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                // проверка на предзаказ
                currentOffers = this.product.offers.filter((offer) => !offer.available);
                isPreOrder = true;
            }

            return {
                currentOffers,
                offer: currentOffers[0],
                offerId: currentOffers[0].id,
                previewImage: this.product.previewImage?.medium,
                price: currentOffers[0].price,
                discountPrice: currentOffers[0].discountPrice,
                discountPercent: currentOffers[0].discountPercent,
                inCart: this.product.inCart || false,
                loading: false,
                isPreOrder,
                previewImageFromOffer: "",
                offerForParams: !this.product.isClone ? undefined : currentOffers[0].id,
            };
        },
        computed: {
            nameProduct() {
                return this.product.name; // this.product.shortName ? this.product.shortName : this.product.name;
            },
            sizeButton() {
                return this.isSmall ? "sm" : "md";
            },
            srcShadow() {
                let filtered = {
                    "picooc-big-pro": "sh_square",
                    "picooc-mini": "sh_square",
                    "picooc-mini-pro": "sh_square",
                    "picooc-mini-pro-v2": "sh_square",
                    "picooc-mini-v2": "sh_square",
                    "picooc-mini-v2-bk": "sh_square",
                    "picooc-s1-pro": "sh_rectangular",
                    "picooc-s1-pro-v2": "sh_rectangular",
                    "picooc-s3-v2": "sh_square",
                    "picooc-mini-lite-wh": "sh_square",
                    "picooc-mini-lite-bk": "sh_square",
                    "picooc-s3-lite-v2": "sh_rectangular",
                    "picooc-fitness-lenta": "sh_tapes",
                    "picooc-x1-pro": "sh_tonometer",
                };
                return filtered[this.product.slug]
                    ? `/static/picooc/products/${filtered[this.product.slug]}.png`
                    : "";
            },
        },
        methods: {
            apiUrl,
            selectColor(color) {
                let { id, previewImage, price, discountPrice, available } =
                    this.currentOffers.filter((offer) => offer.color.id === color.id)[0];
                this.offerId = id;
                this.previewImageFromOffer = previewImage.medium;
                this.price = price;
                this.discountPrice = discountPrice;
                this.isPreOrder = !available;
                this.inCart = false;
            },
            sendDataLayerEvent() {
                sendAnalytics.send(sendAnalytics.events.productClick, {
                    name: this.product.name,
                    offerId: this.offerId,
                    price: this.discountPrice,
                    position: this.position,
                });
            },
        },
    };
</script>

<template>
    <div
        class="product-card"
        :class="{
            'product-card--loading': loading,
            'product-card--sm': isSmall,
            'product-card--tonometer': product.id === 216925,
            'product-card--strip': product.id === 214411,
        }"
    >
        <button-compare
            class="product-card__compare outline"
            v-if="product.canCompared"
            :product-id="product.id"
            :is-clone-product="product.isClone"
        ></button-compare>

        <gift-label v-if="product.showGiftIcon"></gift-label>

        <div class="product-card__header">
            <div class="product-card__img">
                <router-link
                    @click.native.prevent="sendDataLayerEvent"
                    :to="{
                        name: 'Product',
                        params: { slug: product.slug, offer: offerForParams },
                    }"
                    class="product-card__img-link js-lazy-load"
                    tabindex="-1"
                >
                    <!--                    <img class="product-card__img-product" :data-src="apiUrl(previewImage)" />-->
                    <img class="product-card__img-product" :data-src="apiUrl(previewImage)" />
                    <div class="product-card__preloader">
                        <preloader :show="true" lg-size></preloader>
                    </div>

                    <img class="product-card__img-shadow" :data-src="srcShadow" />

                    <img
                        v-if="product.showGiftIcon"
                        class="product-card__img-gift"
                        data-src="/static/picooc/scales/gift.png"
                        alt=""
                    />
                </router-link>
            </div>

            <div v-if="offer.color" class="product-card__colors">
                <color-list
                    :offers="currentOffers"
                    :current-offer="offer"
                    @select-color="selectColor"
                ></color-list>
            </div>
        </div>

        <router-link
            @click.native.prevent="sendDataLayerEvent"
            :to="{
                name: 'Product',
                params: { slug: product.slug, offer: offerForParams },
            }"
            class="product-card__wrap"
        >
            <div class="product-card__name">
                {{ nameProduct }}
            </div>

            <div class="product-card__price">
                <product-price
                    :price="discountPrice"
                    :old-price="price"
                    :discount-count="discountPercent"
                    is-card-size
                ></product-price>
            </div>
        </router-link>

        <product-card-buttons
            :offer-id="offerId"
            :product="product"
            :is-pre-order="isPreOrder"
            :in-cart="inCart"
            :discount-price="discountPrice"
            @on-added-to-cart="inCart = true"
            :size-button="sizeButton"
        ></product-card-buttons>
    </div>
</template>

<style lang="scss">
    .product-card {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        box-shadow: 0px 0px 10px #f2f2f5;
        border-radius: 12px;
        height: auto;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            box-shadow: 0px 0px 25px #f2f2f5;
            border-radius: 20px;
        }

        &:hover {
            box-shadow: 0px 0px 0px 1px var(--hover-scales);

            .product-card__img {
                background-color: var(--hover-scales);
            }

            .product-card__img-link {
                img {
                    transform: scale(1);
                }
            }

            img.product-card__img-gift {
                transform: translateX(10px);
            }

            .product-card__img-shadow {
                filter: hue-rotate(115deg);
            }
        }
    }

    button.product-card__compare {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        white-space: nowrap;
        text-align: center;

        @include bp($bp-desktop-sm) {
            width: 125px;
        }
    }

    .product-card__header {
        position: relative;
        padding: 4px;

        @include bp($bp-desktop-sm) {
            padding: 12px;
        }
    }

    .product-card__img {
        background-color: var(--primary--lighter);
        border-radius: 8px;
        transition: 0.3s ease-in-out background-color;

        @include bp($bp-desktop-sm) {
            border-radius: 15px;
        }
    }

    .product-card__img-link {
        position: relative;
        display: block;
        padding-bottom: 100%;
        overflow: hidden;

        img {
            @include center-absolute(0);
            margin: auto;
            max-height: 100%;
            transform: scale(0.97);
            transition: 0.3s ease-in-out transform, 0.3s ease-in-out filter;
        }
    }

    img.product-card__img-gift {
        z-index: 3;
        transform: translateX(5px);

        @include bp($bp-desktop-sm-for-card) {
            transform: translateX(100%);
        }
    }

    .product-card__preloader {
        opacity: 1;
    }

    .product-card__img-product {
        z-index: 1;
        display: none;
        animation: fadeIn 0.3s ease-in-out;

        @keyframes fadeIn {
            from {
                opacity: 0;
                transform: scale(0.9);
            }
            to {
                opacity: 1;
                transform: scale(0.97);
            }
        }

        &[lazy="loaded"] {
            display: block;
            & + .product-card__preloader {
                opacity: 0;

                & + .product-card__img-shadow {
                    opacity: 1;
                }
            }
        }
    }

    .product-card__img-shadow {
        z-index: 0;
        opacity: 0;
    }

    .product-card__colors {
        position: absolute;
        left: 8px;
        bottom: 8px;
        padding: 0 2px;
        z-index: 5;

        @include bp($bp-desktop-sm) {
            left: 20px;
            bottom: 20px;
        }
    }

    .product-card__wrap {
        display: block;
        margin-top: 4px;
        padding: 0 8px;

        @include bp($bp-desktop-sm) {
            padding: 0 20px;
        }
    }

    .product-card__name {
        display: block;
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: var(--secondary-text); //var(--primary-text);
        margin-bottom: 6px;
        padding-right: 2px;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0;
            padding-right: 10px;
            margin-bottom: 8px;
        }
    }

    .product-card__price {
        display: block;
        margin-bottom: 8px;
    }

    //
    // --mod
    //

    .product-card--loading {
        .product-card__btn {
            opacity: 1;
        }
    }

    .product-card--sm {
        box-shadow: 0 0 10px #f2f2f5;

        .product-card__header {
            padding: 4px;

            @include bp($bp-desktop-sm) {
                padding: 8px;
            }
        }

        .product-card__wrap {
            padding: 0 8px;

            @include bp($bp-desktop-sm) {
                padding: 0 12px;
            }
        }

        .product-card__controls {
            padding: 0 8px 8px;

            @include bp($bp-desktop-sm) {
                padding: 0 12px 12px;
            }
        }

        .product-price__number {
            font-size: 18px;
            line-height: 26px;
        }
    }

    .product-card--tonometer {
        &:hover {
            box-shadow: 0px 0px 0px 1px var(--hover-tonometer);

            .product-card__img {
                background-color: var(--hover-tonometer);
            }

            .product-card__img-shadow {
                opacity: 0.85;
                filter: hue-rotate(-20deg);
            }
        }
    }

    .product-card--strip {
        &:hover {
            box-shadow: 0px 0px 0px 1px var(--hover-strip);

            .product-card__img {
                background-color: var(--hover-strip);
            }

            .product-card__img-shadow {
                filter: hue-rotate(65deg);
            }
        }
    }
</style>
